var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "padding-top": "16px",
      "padding-bottom": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "28px",
      "margin-bottom": "8px",
      "padding-inline": "16px"
    }
  }, [_vm._v(" Apa Kata Mereka? ")]), _c('c-box', {
    attrs: {
      "padding-inline": ['0px', '16px']
    }
  }, [_vm.listTestimoni.length > 0 ? _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '0 16px 8px 0',
          '@media(max-width: 767px)': {
            padding: '0 16px'
          }
        },
        '.slick-dots': {
          bottom: '-25px',
          li: {
            '@media(max-width: 767px)': {
              margin: '0 3px'
            },
            button: {
              '::before': {
                fontSize: '8px',
                color: '#D9D9D9',
                opacity: '1',
                '@media(max-width: 767px)': {
                  fontSize: '10px'
                }
              }
            },
            '&.slick-active': {
              button: {
                '::before': {
                  fontSize: '12px',
                  color: '#008C81',
                  opacity: '1'
                }
              }
            }
          }
        }
      },
      expression: "{\n          '.slick-slide': {\n            padding: '0 16px 8px 0',\n            '@media(max-width: 767px)': {\n              padding: '0 16px',\n            },\n          },\n          '.slick-dots': {\n            bottom: '-25px',\n            li: {\n              '@media(max-width: 767px)': {\n                margin: '0 3px',\n              },\n              button: {\n                '::before': {\n                  fontSize: '8px',\n                  color: '#D9D9D9',\n                  opacity: '1',\n                  '@media(max-width: 767px)': {\n                    fontSize: '10px',\n                  },\n                },\n              },\n              '&.slick-active': {\n                button: {\n                  '::before': {\n                    fontSize: '12px',\n                    color: '#008C81',\n                    opacity: '1',\n                  },\n                },\n              },\n            },\n          },\n        }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.listTestimoni, function (item) {
    return _c('c-box', {
      key: item.id
    }, [_c('c-box', {
      attrs: {
        "position": "relative",
        "width": ['100%', '332px'],
        "h": ['auto', '305px'],
        "background-color": "#008C81",
        "border-radius": "12px",
        "padding": "16px",
        "display": "flex",
        "flex-direction": "column",
        "gap": "8px",
        "padding-bottom": "52px",
        "margin-bottom": "39px"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "flex",
        "gap": "16px"
      }
    }, [_c('c-box', {
      attrs: {
        "min-width": "60px",
        "width": "60px",
        "height": "60px"
      }
    }, [item.image ? _c('c-image', {
      attrs: {
        "src": item.image,
        "height": "100%",
        "width": "100%",
        "border-radius": "100%",
        "object-fit": "cover",
        "object-position": "50% 20%"
      }
    }) : _vm._e()], 1), _c('c-box', {
      attrs: {
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "width": "100%"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "14px-2",
        "size-desktop": "16px",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.age) + " Tahun) ")]), _c('BaseText', {
      attrs: {
        "size-mobile": "12px",
        "size-desktop": "14px-2",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.location) + " ")])], 1)], 1), _c('c-box', {
      attrs: {
        "height": "100%"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "12px",
        "size-desktop": "14px-2",
        "margin-bottom": "auto",
        "text-align": "justify",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1), _c('c-box', {
      attrs: {
        "width": ['190px', '222px'],
        "min-height": ['64px', '67px'],
        "background-color": "white",
        "position": "absolute",
        "bottom": "0",
        "left": "50%",
        "border": "1px solid #008C81",
        "border-radius": "12px",
        "transform": "translate(-50%, 50%)",
        "padding": "5px",
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "gap": "5px"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "10px",
        "size-desktop": "12px"
      }
    }, [_vm._v(" Program yang digunakan: " + _vm._s(item.product.name) + " ")]), _c('BaseButton', {
      attrs: {
        "border-radius": "1000px",
        "right-svg-icon": require('@/assets/icons/icon-arrow-right.svg'),
        "right-svg-icon-color": "white",
        "height": "34px"
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickTestimoniItem(item);
        }
      }
    }, [_vm._v(" Lihat layanan ")])], 1)], 1)], 1);
  }), 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }