<template>
  <c-box
    w="100%"
    max-width="1270px"
    mx="auto"
    :my="['0', '16px']"
    h="auto"
    :bg="['#F2F2F2', '#FFF']"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['1rem', '1.5rem']"
    :min-height="['unset', '74vh']"
  >
    <Stepper
      v-if="!isAuthenticated"
      margin-top="16px"
      :margin-bottom="['12px', '20px']"
      :steps="[
        {
          label: 'Pilih Program',
          isCurrentStep: true,
        },
        {
          label: 'Informasi Pribadi',
        },
        {
          label: 'Periksa',
        },
        {
          label: 'Pembayaran',
        },
      ]"
    />
    <c-box
      :max-width="['auto', '1024px']"
      margin="auto"
    >
      <c-text
        font-family="Roboto"
        :font-size="['16px', '28px']"
        :font-weight="['600', '700']"
        text-align="center"
        :mb="['1rem', '1.5rem']"
      >
        Pilih berdasarkan Keinginanmu
      </c-text>
      
      <c-box
        v-if="data"
        margin-bottom="16px"
        margin-inline="auto"
        max-width="950px"
      >
        <CardSimpleDetailProgram
          :product-slug="data?.product?.slug"
          :product-description="data?.product?.description"
          :product-name="data?.product?.name"
          :product-id="data?.product?.id"
          :product-photo-src="data?.product?.photoUrl"
          :ribbon-text="data?.product?.badge"
          :custom-url="data?.product?.customURL"
        />
      </c-box>
      <c-box margin-bottom="16px" />

      <c-box
        v-if="data"
        width="100%"
        max-width="1100px"
        margin-top="16px"
        margin-inline="auto"
      >
        <c-box
          :display="['none', 'block']"
          overflow="hidden"
          border-radius="8px" 
          box-shadow="0 0 0 1px #005A64"
        >
          <c-box
            as="table"
            position="relative"
            width="100%"
            border="none"
            table-layout="fixed"
            class="table-comparation"
          >
            <c-box 
              as="thead"
              color="#FFF"
              font-weight="600"
              background-color="primary.400" 
            >
              <c-box
                as="tr"
                border-width="1px"
                border-color="primary.400" 
              >
                <c-box
                  as="th"
                  padding-inline="16px"
                  text-align="center"
                  font-weight="600"
                  font-size="20px"
                  color="#FFF"
                >
                  Fasilitas Layanan
                </c-box>
                <c-box
                  as="th"
                  v-for="item in data?.comparation"
                  :key="item.id"
                  position="relative"
                  width="280px"
                  border-left-width="1px"
                  border-left-color="primary.500"
                  padding-block="16px"
                  text-align="center"
                >
                  <c-text
                    margin-bottom="4px"
                    color="#FFF"
                    font-size="20px"
                    font-weight="600"
                  >
                    <c-box
                      as="span"
                      font-style="italic"
                    >
                      {{ item?.programsService }}
                    </c-box>
                    {{ item?.duration }} Hari
                  </c-text>
                  <c-box
                    font-size="16px"
                    font-weight="500"
                    color="#FFF"
                  >
                    ({{
                      Math.floor(
                        currency(item?.price, {
                          precision: 0,
                        }).divide(item?.duration).intValue / 1000,
                      )
                    }}
                    ribuan/hari)
                  </c-box>

                  <c-box
                    width="80%"
                    position="absolute"
                    top="80px"
                    left="50%"
                    transform="translateX(-50%)"
                    margin-inline="auto"
                    text-align="center"
                    padding-inline="4px"
                    padding-block="4px"
                    font-size="14px"
                    font-weight="500"
                    color="primary.400"
                    background-color="secondary.50"
                  >
                    {{ item?.description || '' }}
                  </c-box>
                </c-box>
              </c-box>
            </c-box>
            <c-box
              as="tbody"
              border="none"
              background-color="#FFF"
              font-size="16px"
              color="#000"
            >
              <c-box
                as="tr"
                v-for="(facility, i) in programFacilities"
                :key="`tr-${i}`"
              >
                <c-box
                  as="td"
                  v-for="k in data?.comparation?.length + 1"
                  :key="`td-${i}-${k}`"
                  padding-inline="8px"
                  :padding-top="i === 0 ? '24px' : '0px'"
                  :border-left="k !== 1 ? '1px solid #C4C4C4' : 'none'"
                  position="relative"
                >
                  <c-box
                    v-if="k === 1"
                    border-bottom="1px solid #C4C4C4"
                    padding-inline="12px"
                    padding-block="16px"
                    text-align="left"
                    list-style-position="inside"
                    v-html="isObjectFacility(facility) ? convertJsonFormat(facility)?.desktop : facility"
                  />
                  <c-box
                    v-else
                    position="absolute"
                    top="0"
                    right="0"
                    bottom="0"
                    left="0"
                    margin-inline="8px"
                    display="flex"
                    height="100%"
                    justify-content="center"
                    align-items="center"
                    border-bottom="1px solid #C4C4C4"
                    padding-block="16px"
                  >
                    <inline-svg
                      v-if="isCheckList(getFacilityValue(facility, data?.comparation[k - 2]?.productFacilitiesComparation))"
                      :src="
                        checkIcon(getFacilityValue(facility, data?.comparation[k - 2]?.productFacilitiesComparation))
                          ? iconCircleCheck
                          : iconCircleClose
                      "
                      class="icon-list"
                      :fill="
                        checkIcon(getFacilityValue(facility, data?.comparation[k - 2]?.productFacilitiesComparation))
                          ? 'var(--program-400)'
                          : '#D32737'
                      "
                    />
                    <c-box
                      as="span"
                      v-else
                    >
                      {{
                        getFacilityValue(
                          facility,
                          data?.comparation[k - 2]
                            ?.productFacilitiesComparation,
                        )
                      }}
                    </c-box>
                  </c-box>
                </c-box>
              </c-box>

              <c-box
                as="tr"
              >
                <c-box
                  as="td"
                />
                <c-box
                  as="td"
                  v-for="item in data?.comparation"
                  :key="`buy-${item?.id}`"
                  border-left="1px solid #C4C4C4"
                  padding-inline="12px"
                  padding-block="16px"
                >
                  <c-box 
                    display="flex"
                    flex-direction="column"
                    align-items="center"
                    justify-content="center"
                    gap="12px"
                  >
                    <c-text
                      text-aling="center"
                      font-size="32px"
                      font-weight="700"
                    >
                      {{
                        currency(item?.price, {
                          separator: '.',
                          precision: 0,
                          symbol: 'Rp ',
                        }).format()
                      }}
                    </c-text>

                    <c-flex
                      width="100%"
                      :gap="['8px', '16px']"
                    >
                      <c-flex
                        v-if="isAuthenticated"
                        justify-content="center"
                        align-items="center"
                        :height="['34px', '50px']"
                        :width="['34px', '50px']"
                        :min-height="['34px', '50px']"
                        :min-width="['34px', '50px']"
                        border="1px solid #008C81"
                        border-radius="12px"
                        :cursor="item?.isDisablePurchase || isLoading.addToCart ? 'not-allowed' : 'pointer'"
                        :style="item?.isDisablePurchase ? 'pointer-events: not-allowed; opacity: 0.5;' : ''"
                        :_hover="!item?.isDisablePurchase ? { backgroundColor: '#e9fffb' } : {}"
                        @click="!item?.isDisablePurchase && handleAddToCart({ productServiceId: item?.id, comparation: item, photoUrl: data?.product?.photoUrl })"
                      >
                        <c-circular-progress
                          v-if="isLoading.addToCart"
                          is-indeterminate
                          color="primary"
                          size="100%"
                        />
                        <inline-svg
                          v-else
                          :src="require('@/assets/icons/icon-cart.svg')"
                          width="100%"
                          min-width="100%"
                          fill="#008C81"
                        />
                      </c-flex>
                      <BaseButton
                        v-chakra="{
                          color: 'primary.400',
                          fontWeight: ['400', '500'],
                          fontSize: ['12px', '18px']
                        }"
                        size="small"
                        variant="contained"
                        w="100%"
                        border-radius="1000px"
                        color="secondary"
                        :is-loading="isLoading.buyNow"
                        :disabled="item?.isDisablePurchase"
                        @click="
                          handleClickBuyNow({
                            productId: item?.productId,
                            productServiceId: item?.id,
                            duration: item?.duration,
                          })
                        "
                      >
                        Beli Sekarang
                      </BaseButton>
                    </c-flex>
                  </c-box>
                </c-box>
              </c-box>
            </c-box>
          </c-box>
        </c-box>
        <c-box
          :display="['block', 'none']"
          width="100%"
          background-color="#FFF"
          overflow="hidden"
          border-radius="8px"
          border-width="1px"
          border-color="primary.400"
          z-index="10"
        >
          <c-grid 
            :template-columns="`repeat(${data.comparation.length}, 1fr)`"
            background-color="primary.400"
            padding-block="4px"
          >
            <c-box
              v-for="(item, w) in data?.comparation"
              :key="item.programsService"
              display="flex"
              flex-direction="column"
              text-align="center"
              margin-block="6px"
              :border-left-color="w !== 0 ? 'primary.700' : ''"
              :border-left-width="w !== 0 ? '1px' : ''"
            >
              <c-text
                margin-bottom="4px"
                font-size="14px"
                font-weight="500"
                color="#FFF"
              >
                <c-box
                  as="span"
                  font-style="italic"
                >
                  {{ item?.programsService }}
                </c-box>
                {{ item?.duration }} Hari
              </c-text>
              <c-text
                font-size="12px"
                color="#FFF"
              >
                ({{
                  Math.floor(
                    currency(item?.price, {
                      precision: 0,
                    }).divide(item?.duration).intValue / 1000,
                  )
                }}
                ribuan/hari)
              </c-text>
            </c-box>
          </c-grid>
          <c-box
            position="relative"
            width="100%"
            padding-inline="8px"
            padding-bottom="8px"
          >
            <c-grid
              :template-columns="`repeat(${data.comparation.length}, 1fr)`"
              gap="8px"
            >
              <c-box
                v-for="item in data?.comparation"
                :key="item.programsService"
                margin-top="-4px"
                width="100%"
                height="fit-content"
                border-bottom-left-radius="8px"
                border-bottom-right-radius="8px"
                background-color="secondary.50"
                padding="8px"
                text-align="center"
                font-size="12px"
                color="primary.400"
              >
                {{ item?.description || '' }}
              </c-box>
            </c-grid>
          </c-box>
          <c-box
            width="100%"
            padding="8px"
          >
            <c-box
              v-for="(facility, index) in programFacilities"
              :key="`facility-${index}`"
            >
              <c-box
                background-color="primary.50"
                padding-inline="8px"
                padding-block="6px"
                text-align="center"
                font-size="12px"
                v-html="isObjectFacility(facility) ? convertJsonFormat(facility)?.mobile : facility"
              />
              <c-grid
                width="100%"
                :template-columns="`repeat(${data.comparation.length}, 1fr)`"
                padding-block="6px"
              >
                <c-flex
                  v-for="j in data?.comparation?.length"
                  :key="`td-${index}-${j}`"
                  width="100%"
                  flex-direction="column"
                  align-items="center"
                  padding-block="4px"
                  font-size="12px"
                  :border-left="j !== 1 ? '1px solid #C4C4C4' : ''"
                >
                  <inline-svg
                    v-if="isCheckList(getFacilityValue(facility, data?.comparation[j - 1]?.productFacilitiesComparation))"
                    :src="
                      checkIcon(getFacilityValue(facility, data?.comparation[j - 1]?.productFacilitiesComparation))
                        ? iconCircleCheck
                        : iconCircleClose
                    "
                    class="icon-list"
                    :fill="
                      checkIcon(getFacilityValue(facility, data?.comparation[j - 1]?.productFacilitiesComparation))
                        ? 'var(--program-400)'
                        : '#D32737'
                    "
                  />
                  <c-box
                    as="span"
                    v-else
                    text-align="center"
                  >
                    {{
                      getFacilityValue(
                        facility,
                        data?.comparation[j - 1]
                          ?.productFacilitiesComparation,
                      )
                    }}
                  </c-box>
                </c-flex>
              </c-grid>
            </c-box>
          </c-box>
          <c-box
            width="100%"
            padding-block="8px"
          >
            <c-grid
              :template-columns="`repeat(${data.comparation.length}, 1fr)`"
              width="100%"
            >
              <c-box
                v-for="(item, q) in data?.comparation"
                :key="`buy-${item?.id}`"
                padding-inline="8px"
                :border-left="q !== 0 ? '1px solid #C4C4C4' : ''"
              >
                <c-flex
                  flex-direction="column"
                  align-items="center"
                  justify-content="center"
                  gap="12px"
                >
                  <c-text
                    text-align="center"
                    font-size="20px"
                    font-weight="700"
                  >
                    {{
                      currency(item?.price, {
                        separator: '.',
                        precision: 0,
                        symbol: 'Rp ',
                      }).format()
                    }}
                  </c-text>

                  <c-flex
                    width="100%"
                    :gap="['8px', '16px']"
                  >
                    <c-flex
                      v-if="isAuthenticated"
                      justify-content="center"
                      align-items="center"
                      :height="['34px', '50px']"
                      :width="['34px', '50px']"
                      :min-height="['34px', '50px']"
                      :min-width="['34px', '50px']"
                      border="1px solid #008C81"
                      border-radius="12px"
                      :cursor="item?.isDisablePurchase || isLoading.addToCart ? 'not-allowed' : 'pointer'"
                      :style="item?.isDisablePurchase ? 'pointer-events: none; opacity: 0.5;' : ''"
                      :_hover="!item?.isDisablePurchase ? { backgroundColor: '#e9fffb' } : {}"
                      @click="!item?.isDisablePurchase && handleAddToCart({ productServiceId: item?.id, comparation: item, photoUrl: data?.product?.photoUrl })"
                    >
                      <c-circular-progress
                        v-if="isLoading.addToCart"
                        is-indeterminate
                        color="primary"
                        size="100%"
                      />
                      <inline-svg
                        v-else
                        :src="require('@/assets/icons/icon-cart.svg')"
                        width="100%"
                        min-width="100%"
                        fill="#008C81"
                      />
                    </c-flex>
                    <BaseButton
                      v-chakra="{
                        color: 'primary.400',
                        fontWeight: ['600', '600'],
                        fontSize: ['12px', '18px']
                      }"
                      size="small"
                      variant="contained"
                      w="100%"
                      border-radius="1000px"
                      color="secondary"
                      :is-loading="isLoading.buyNow"
                      :disabled="item?.isDisablePurchase"
                      @click="
                        handleClickBuyNow({
                          productId: item?.productId,
                          productServiceId: item?.id,
                          duration: item?.duration,
                        })
                      "
                    >
                      Beli Sekarang
                    </BaseButton>
                  </c-flex>
                </c-flex>
              </c-box>
            </c-grid>
          </c-box>
          <c-text
            :padding="['8px', '16px']"
            :font-size="['12px', '16px']"
            text-align="center"
          >
            {{ additionalInfo }}
          </c-text>
        </c-box>
      </c-box>
      <c-text
        :display="['none', 'block']"
        :padding-top="['8px', '16px']"
        :font-size="['12px', '16px']"
        text-align="center"
      >
        {{ additionalInfo }}
      </c-text>
    </c-box>
    <c-box
      width="100%"
      max-width="1100px"
      margin-inline="auto"
      :margin-top="['20px', '30px']"
    >
      <Testimoni v-if="isAuthenticated" />
      <Promos v-if="!isAuthenticated" />
    </c-box>
  </c-box>
</template>

<script>
import { reqProgram } from '@/requests/dietela-api/general/program'
import Stepper from '@/components/elements/stepper.vue'
import CardSimpleDetailProgram from '@/components/card-simple-detail-program.vue'
import iconCircleClose from '@/assets/icons/icon-circle-close.svg'
import iconCircleCheck from '@/assets/icons/icon-circle-check.svg'
import BaseButton from '@/components/elements/base-button.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import currency from 'currency.js'
import Testimoni from '@/components/testimoni.vue'
import Promos from '@/views/pricing/promos.vue'

export default {
  name: 'IfProgram',
  components: {
    Stepper,
    CardSimpleDetailProgram,
    BaseButton,
    Testimoni,
    Promos,
  },
  data() {
    return {
      isLoadingProduct: false,
      isLoading: {
        addToCart: false,
        buyNow: false,
      },
      data: null,
      iconCircleClose,
      iconCircleCheck,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
    programFacilities() {
      return this.data?.facilities?.map((it) => it?.facility) || []
    },
    additionalInfo() {
      return '*variasi perubahan BB bergantung kepada konsistensi melakukan IF, motivasi, kepatuhan diet klien, dan BB awal'
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    currency,
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
    }),
    ...mapActions({
      onUpdateCart: 'cart/onUpdateCart',
      list: 'cart/list',
    }),
    async init() {
      this.isLoadingProduct = true
      const res = await reqProgram.comparation(this.$store.getters.axios, {
        productId: 'if-program',
      })
      this.data = res?.data?.data
      this.isLoadingProduct = false
    },
    isObjectFacility(value) {
      const conditions = ['desktop', 'mobile']
      return conditions.some((it) => value.includes(it))
    },
    getValueRegex(inputString, key) {
      const str = String(inputString)
      const regex = new RegExp(`${key}:\\s*'([^']+)'`)
      const match = str.match(regex)
      return match ? match[1] : null
    },
    convertJsonFormat(value) {
      return {
        desktop: this.getValueRegex(`${value}`, 'desktop'),
        mobile: this.getValueRegex(`${value}`, 'mobile'),
      }
    },
    getFacilityValue(label, data) {
      return data?.find(it => it?.productFacilities?.facility === label)?.value
    },
    isCheckList(value) {
      if (value && value?.includes('[x]') || value?.includes('[v]')) {
        return 1
      }
      return 0
    },
    checkIcon(value) {
      if (value && value?.includes('[x]')) {
        return 0
      }
      return 1
    },
    async handleAddToCart({ productServiceId, comparation, photoUrl }) {
      if (this.isLoading.addToCart) {
        return
      }
      if (!this.isAuthenticated) {
        this.$router.push({ name: 'auth', query: { tab: 'login' } })
        return
      }
      this.isLoading.addToCart = true
      try {
        let params = {
          productServiceId: productServiceId,
          extend: 1,
        }
        await this.onUpdateCart(params)
          .then(() => {
            const findIndex = this.cartItems.findIndex((v) => v.productService?.id === productServiceId)
            if (findIndex === -1) {
              this.cartItems.push({
                quantity: 1,
                productService: {
                  ...comparation,
                  product: {
                    photoUrl: photoUrl,
                  },
                },
              })
              return
            }
            const cartItem = this.cartItems[findIndex]
            cartItem.quantity++
          })
        await this.list()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading.addToCart = false
      }
    },
    handleClickBuyNow({ productId, productServiceId, duration }) {
      if (!this.isAuthenticated) {
        this.$router.push({ 
          name: 'client.buy.step-2',
          query: {
            product_id: productId,
            product_service_id: productServiceId,
            duration: duration,
          },
        })
        return
      }

      const selectedCart = {
        quantity: 1,
        productService: {
          id: productServiceId,
          product: {
            ...this.data?.product,
          },
        },
        _isBuyNow: true,
      }
      this.setSelectedCart(selectedCart)
      this.$router.push({ name: 'client.cart.check-order' })
    },
  },
}
</script>

<style scoped>
.table-comparation {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  overflow: hidden;
  border-radius: 8px;
}
.table-comparation td[colspan]:not([colspan='1']) {
  border-right: 1px solid var(--program-400);
}
.icon-list {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .icon-list {
    width: 20px;
    height: 20px;
  }
}
</style>
