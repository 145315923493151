<template>
  <c-box
    padding-top="16px"
    padding-bottom="16px"
  >
    <c-box
      position="relative"
      width="100%"
      display="flex"
      flex-direction="column"
      justify-content="center"
    >
      <BaseText
        size-mobile="16px"
        size-desktop="28px"
        margin-bottom="8px"
        padding-inline="16px"
      >
        Apa Kata Mereka?
      </BaseText>

      <c-box
        :padding-inline="['0px', '16px']"
      >
        <VueSlickCarousel
          v-if="listTestimoni.length > 0"
          v-chakra="{
            '.slick-slide': {
              padding: '0 16px 8px 0',
              '@media(max-width: 767px)': {
                padding: '0 16px',
              },
            },
            '.slick-dots': {
              bottom: '-25px',
              li: {
                '@media(max-width: 767px)': {
                  margin: '0 3px',
                },
                button: {
                  '::before': {
                    fontSize: '8px',
                    color: '#D9D9D9',
                    opacity: '1',
                    '@media(max-width: 767px)': {
                      fontSize: '10px',
                    },
                  },
                },
                '&.slick-active': {
                  button: {
                    '::before': {
                      fontSize: '12px',
                      color: '#008C81',
                      opacity: '1',
                    },
                  },
                },
              },
            },
          }"
          v-bind="settings"
        >
          <c-box
            v-for="(item) in listTestimoni"
            :key="item.id"
          >
            <c-box
              position="relative"
              :width="['100%', '332px']"
              :h="['auto', '305px']"
              background-color="#008C81"
              border-radius="12px"
              padding="16px"
              display="flex"
              flex-direction="column"
              gap="8px"
              padding-bottom="52px"
              margin-bottom="39px"
            >
              <c-box
                display="flex"
                gap="16px"
              >
                <c-box
                  min-width="60px"
                  width="60px"
                  height="60px"
                >
                  <c-image
                    v-if="item.image"
                    :src="item.image"
                    height="100%"
                    width="100%"
                    border-radius="100%"
                    object-fit="cover"
                    object-position="50% 20%"
                  />
                </c-box>
                <c-box
                  display="flex"
                  flex-direction="column"
                  justify-content="center"
                  width="100%"
                >
                  <BaseText
                    size-mobile="14px-2"
                    size-desktop="16px"
                    color="white"
                  >
                    {{ item.name }} ({{ item.age }} Tahun)
                  </BaseText>
                  <BaseText
                    size-mobile="12px"
                    size-desktop="14px-2"
                    color="white"
                  >
                    {{ item.location }}
                  </BaseText>
                </c-box>
              </c-box>
              <c-box
                height="100%"
              >
                <BaseText
                  size-mobile="12px"
                  size-desktop="14px-2"
                  margin-bottom="auto"
                  text-align="justify"
                  color="white"
                >
                  {{ item.description }}
                </BaseText>
              </c-box>
              <c-box
                :width="['190px','222px']"
                :min-height="['64px', '67px']"
                background-color="white"
                position="absolute"
                bottom="0"
                left="50%"
                border="1px solid #008C81"
                border-radius="12px"
                transform="translate(-50%, 50%)"
                padding="5px"
                display="flex"
                flex-direction="column"
                justify-content="center"
                align-items="center"
                gap="5px"
              >
                <BaseText
                  size-mobile="10px"
                  size-desktop="12px"
                >
                  Program yang digunakan: {{ item.product.name }}
                </BaseText>
                <BaseButton
                  border-radius="1000px"
                  :right-svg-icon="require('@/assets/icons/icon-arrow-right.svg')"
                  right-svg-icon-color="white"
                  height="34px"
                  @click="handleClickTestimoniItem(item)"
                >
                  Lihat layanan
                </BaseButton>
              </c-box>
            </c-box>
          </c-box>
        </VueSlickCarousel>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapActions } from 'vuex'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Testimoni',
  components: {
    BaseText,
    BaseButton,
    VueSlickCarousel,
  },
  data() {
    return {
      listTestimoni: [],
      settings: {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 5000,
        variableWidth: true,
        slidesToScroll: 1,
        pauseOnHover: true,
        pauseOnFocus: true,
        pauseOnDotsHover: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: true,
              adaptiveHeight: true,
              variableWidth: false,
              slidesToShow: 1,
            },
          },
        ],
      }, 
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      getTestimoni: 'general/getTestimoni',
    }),
    async init() {
      const res = await this.getTestimoni()
      this.listTestimoni = res.data
    },
  },
}
</script>