var render = function () {
  var _vm$data, _vm$data$product, _vm$data2, _vm$data2$product, _vm$data3, _vm$data3$product, _vm$data4, _vm$data4$product, _vm$data5, _vm$data5$product, _vm$data6, _vm$data6$product, _vm$data7, _vm$data7$product, _vm$data8, _vm$data14, _vm$data16, _vm$data17, _vm$data23;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1270px",
      "mx": "auto",
      "my": ['0', '16px'],
      "h": "auto",
      "bg": ['#F2F2F2', '#FFF'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['1rem', '1.5rem'],
      "min-height": ['unset', '74vh']
    }
  }, [!_vm.isAuthenticated ? _c('Stepper', {
    attrs: {
      "margin-top": "16px",
      "margin-bottom": ['12px', '20px'],
      "steps": [{
        label: 'Pilih Program',
        isCurrentStep: true
      }, {
        label: 'Informasi Pribadi'
      }, {
        label: 'Periksa'
      }, {
        label: 'Pembayaran'
      }]
    }
  }) : _vm._e(), _c('c-box', {
    attrs: {
      "max-width": ['auto', '1024px'],
      "margin": "auto"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '28px'],
      "font-weight": ['600', '700'],
      "text-align": "center",
      "mb": ['1rem', '1.5rem']
    }
  }, [_vm._v(" Pilih berdasarkan Keinginanmu ")]), _vm.data ? _c('c-box', {
    attrs: {
      "margin-bottom": "16px",
      "margin-inline": "auto",
      "max-width": "950px"
    }
  }, [_c('CardSimpleDetailProgram', {
    attrs: {
      "product-slug": (_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$product = _vm$data.product) === null || _vm$data$product === void 0 ? void 0 : _vm$data$product.slug,
      "product-description": (_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$product = _vm$data2.product) === null || _vm$data2$product === void 0 ? void 0 : _vm$data2$product.description,
      "product-name": (_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : (_vm$data3$product = _vm$data3.product) === null || _vm$data3$product === void 0 ? void 0 : _vm$data3$product.name,
      "product-id": (_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : (_vm$data4$product = _vm$data4.product) === null || _vm$data4$product === void 0 ? void 0 : _vm$data4$product.id,
      "product-photo-src": (_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : (_vm$data5$product = _vm$data5.product) === null || _vm$data5$product === void 0 ? void 0 : _vm$data5$product.photoUrl,
      "ribbon-text": (_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : (_vm$data6$product = _vm$data6.product) === null || _vm$data6$product === void 0 ? void 0 : _vm$data6$product.badge,
      "custom-url": (_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : (_vm$data7$product = _vm$data7.product) === null || _vm$data7$product === void 0 ? void 0 : _vm$data7$product.customURL
    }
  })], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "margin-bottom": "16px"
    }
  }), _vm.data ? _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1100px",
      "margin-top": "16px",
      "margin-inline": "auto"
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['none', 'block'],
      "overflow": "hidden",
      "border-radius": "8px",
      "box-shadow": "0 0 0 1px #005A64"
    }
  }, [_c('c-box', {
    staticClass: "table-comparation",
    attrs: {
      "as": "table",
      "position": "relative",
      "width": "100%",
      "border": "none",
      "table-layout": "fixed"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "thead",
      "color": "#FFF",
      "font-weight": "600",
      "background-color": "primary.400"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "tr",
      "border-width": "1px",
      "border-color": "primary.400"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "th",
      "padding-inline": "16px",
      "text-align": "center",
      "font-weight": "600",
      "font-size": "20px",
      "color": "#FFF"
    }
  }, [_vm._v(" Fasilitas Layanan ")]), _vm._l((_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : _vm$data8.comparation, function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "as": "th",
        "position": "relative",
        "width": "280px",
        "border-left-width": "1px",
        "border-left-color": "primary.500",
        "padding-block": "16px",
        "text-align": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "margin-bottom": "4px",
        "color": "#FFF",
        "font-size": "20px",
        "font-weight": "600"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "span",
        "font-style": "italic"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.programsService) + " ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.duration) + " Hari ")], 1), _c('c-box', {
      attrs: {
        "font-size": "16px",
        "font-weight": "500",
        "color": "#FFF"
      }
    }, [_vm._v(" (" + _vm._s(Math.floor(_vm.currency(item === null || item === void 0 ? void 0 : item.price, {
      precision: 0
    }).divide(item === null || item === void 0 ? void 0 : item.duration).intValue / 1000)) + " ribuan/hari) ")]), _c('c-box', {
      attrs: {
        "width": "80%",
        "position": "absolute",
        "top": "80px",
        "left": "50%",
        "transform": "translateX(-50%)",
        "margin-inline": "auto",
        "text-align": "center",
        "padding-inline": "4px",
        "padding-block": "4px",
        "font-size": "14px",
        "font-weight": "500",
        "color": "primary.400",
        "background-color": "secondary.50"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.description) || '') + " ")])], 1);
  })], 2)], 1), _c('c-box', {
    attrs: {
      "as": "tbody",
      "border": "none",
      "background-color": "#FFF",
      "font-size": "16px",
      "color": "#000"
    }
  }, [_vm._l(_vm.programFacilities, function (facility, i) {
    var _vm$data9, _vm$data9$comparation;

    return _c('c-box', {
      key: "tr-".concat(i),
      attrs: {
        "as": "tr"
      }
    }, _vm._l(((_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : (_vm$data9$comparation = _vm$data9.comparation) === null || _vm$data9$comparation === void 0 ? void 0 : _vm$data9$comparation.length) + 1, function (k) {
      var _vm$convertJsonFormat, _vm$data10, _vm$data10$comparatio, _vm$data11, _vm$data11$comparatio, _vm$data12, _vm$data12$comparatio, _vm$data13, _vm$data13$comparatio;

      return _c('c-box', {
        key: "td-".concat(i, "-").concat(k),
        attrs: {
          "as": "td",
          "padding-inline": "8px",
          "padding-top": i === 0 ? '24px' : '0px',
          "border-left": k !== 1 ? '1px solid #C4C4C4' : 'none',
          "position": "relative"
        }
      }, [k === 1 ? _c('c-box', {
        attrs: {
          "border-bottom": "1px solid #C4C4C4",
          "padding-inline": "12px",
          "padding-block": "16px",
          "text-align": "left",
          "list-style-position": "inside"
        },
        domProps: {
          "innerHTML": _vm._s(_vm.isObjectFacility(facility) ? (_vm$convertJsonFormat = _vm.convertJsonFormat(facility)) === null || _vm$convertJsonFormat === void 0 ? void 0 : _vm$convertJsonFormat.desktop : facility)
        }
      }) : _c('c-box', {
        attrs: {
          "position": "absolute",
          "top": "0",
          "right": "0",
          "bottom": "0",
          "left": "0",
          "margin-inline": "8px",
          "display": "flex",
          "height": "100%",
          "justify-content": "center",
          "align-items": "center",
          "border-bottom": "1px solid #C4C4C4",
          "padding-block": "16px"
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility, (_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : (_vm$data10$comparatio = _vm$data10.comparation[k - 2]) === null || _vm$data10$comparatio === void 0 ? void 0 : _vm$data10$comparatio.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$data11 = _vm.data) === null || _vm$data11 === void 0 ? void 0 : (_vm$data11$comparatio = _vm$data11.comparation[k - 2]) === null || _vm$data11$comparatio === void 0 ? void 0 : _vm$data11$comparatio.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$data12 = _vm.data) === null || _vm$data12 === void 0 ? void 0 : (_vm$data12$comparatio = _vm$data12.comparation[k - 2]) === null || _vm$data12$comparatio === void 0 ? void 0 : _vm$data12$comparatio.productFacilitiesComparation)) ? 'var(--program-400)' : '#D32737'
        }
      }) : _c('c-box', {
        attrs: {
          "as": "span"
        }
      }, [_vm._v(" " + _vm._s(_vm.getFacilityValue(facility, (_vm$data13 = _vm.data) === null || _vm$data13 === void 0 ? void 0 : (_vm$data13$comparatio = _vm$data13.comparation[k - 2]) === null || _vm$data13$comparatio === void 0 ? void 0 : _vm$data13$comparatio.productFacilitiesComparation)) + " ")])], 1)], 1);
    }), 1);
  }), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }), _vm._l((_vm$data14 = _vm.data) === null || _vm$data14 === void 0 ? void 0 : _vm$data14.comparation, function (item) {
    return _c('c-box', {
      key: "buy-".concat(item === null || item === void 0 ? void 0 : item.id),
      attrs: {
        "as": "td",
        "border-left": "1px solid #C4C4C4",
        "padding-inline": "12px",
        "padding-block": "16px"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "flex",
        "flex-direction": "column",
        "align-items": "center",
        "justify-content": "center",
        "gap": "12px"
      }
    }, [_c('c-text', {
      attrs: {
        "text-aling": "center",
        "font-size": "32px",
        "font-weight": "700"
      }
    }, [_vm._v(" " + _vm._s(_vm.currency(item === null || item === void 0 ? void 0 : item.price, {
      separator: '.',
      precision: 0,
      symbol: 'Rp '
    }).format()) + " ")]), _c('c-flex', {
      attrs: {
        "width": "100%",
        "gap": ['8px', '16px']
      }
    }, [_vm.isAuthenticated ? _c('c-flex', {
      style: item !== null && item !== void 0 && item.isDisablePurchase ? 'pointer-events: not-allowed; opacity: 0.5;' : '',
      attrs: {
        "justify-content": "center",
        "align-items": "center",
        "height": ['34px', '50px'],
        "width": ['34px', '50px'],
        "min-height": ['34px', '50px'],
        "min-width": ['34px', '50px'],
        "border": "1px solid #008C81",
        "border-radius": "12px",
        "cursor": item !== null && item !== void 0 && item.isDisablePurchase || _vm.isLoading.addToCart ? 'not-allowed' : 'pointer',
        "_hover": !(item !== null && item !== void 0 && item.isDisablePurchase) ? {
          backgroundColor: '#e9fffb'
        } : {}
      },
      on: {
        "click": function click($event) {
          var _vm$data15, _vm$data15$product;

          !(item !== null && item !== void 0 && item.isDisablePurchase) && _vm.handleAddToCart({
            productServiceId: item === null || item === void 0 ? void 0 : item.id,
            comparation: item,
            photoUrl: (_vm$data15 = _vm.data) === null || _vm$data15 === void 0 ? void 0 : (_vm$data15$product = _vm$data15.product) === null || _vm$data15$product === void 0 ? void 0 : _vm$data15$product.photoUrl
          });
        }
      }
    }, [_vm.isLoading.addToCart ? _c('c-circular-progress', {
      attrs: {
        "is-indeterminate": "",
        "color": "primary",
        "size": "100%"
      }
    }) : _c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-cart.svg'),
        "width": "100%",
        "min-width": "100%",
        "fill": "#008C81"
      }
    })], 1) : _vm._e(), _c('BaseButton', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          color: 'primary.400',
          fontWeight: ['400', '500'],
          fontSize: ['12px', '18px']
        },
        expression: "{\n                        color: 'primary.400',\n                        fontWeight: ['400', '500'],\n                        fontSize: ['12px', '18px']\n                      }"
      }],
      attrs: {
        "size": "small",
        "variant": "contained",
        "w": "100%",
        "border-radius": "1000px",
        "color": "secondary",
        "is-loading": _vm.isLoading.buyNow,
        "disabled": item === null || item === void 0 ? void 0 : item.isDisablePurchase
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickBuyNow({
            productId: item === null || item === void 0 ? void 0 : item.productId,
            productServiceId: item === null || item === void 0 ? void 0 : item.id,
            duration: item === null || item === void 0 ? void 0 : item.duration
          });
        }
      }
    }, [_vm._v(" Beli Sekarang ")])], 1)], 1)], 1);
  })], 2)], 2)], 1)], 1), _c('c-box', {
    attrs: {
      "display": ['block', 'none'],
      "width": "100%",
      "background-color": "#FFF",
      "overflow": "hidden",
      "border-radius": "8px",
      "border-width": "1px",
      "border-color": "primary.400",
      "z-index": "10"
    }
  }, [_c('c-grid', {
    attrs: {
      "template-columns": "repeat(".concat(_vm.data.comparation.length, ", 1fr)"),
      "background-color": "primary.400",
      "padding-block": "4px"
    }
  }, _vm._l((_vm$data16 = _vm.data) === null || _vm$data16 === void 0 ? void 0 : _vm$data16.comparation, function (item, w) {
    return _c('c-box', {
      key: item.programsService,
      attrs: {
        "display": "flex",
        "flex-direction": "column",
        "text-align": "center",
        "margin-block": "6px",
        "border-left-color": w !== 0 ? 'primary.700' : '',
        "border-left-width": w !== 0 ? '1px' : ''
      }
    }, [_c('c-text', {
      attrs: {
        "margin-bottom": "4px",
        "font-size": "14px",
        "font-weight": "500",
        "color": "#FFF"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "span",
        "font-style": "italic"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.programsService) + " ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.duration) + " Hari ")], 1), _c('c-text', {
      attrs: {
        "font-size": "12px",
        "color": "#FFF"
      }
    }, [_vm._v(" (" + _vm._s(Math.floor(_vm.currency(item === null || item === void 0 ? void 0 : item.price, {
      precision: 0
    }).divide(item === null || item === void 0 ? void 0 : item.duration).intValue / 1000)) + " ribuan/hari) ")])], 1);
  }), 1), _c('c-box', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "padding-inline": "8px",
      "padding-bottom": "8px"
    }
  }, [_c('c-grid', {
    attrs: {
      "template-columns": "repeat(".concat(_vm.data.comparation.length, ", 1fr)"),
      "gap": "8px"
    }
  }, _vm._l((_vm$data17 = _vm.data) === null || _vm$data17 === void 0 ? void 0 : _vm$data17.comparation, function (item) {
    return _c('c-box', {
      key: item.programsService,
      attrs: {
        "margin-top": "-4px",
        "width": "100%",
        "height": "fit-content",
        "border-bottom-left-radius": "8px",
        "border-bottom-right-radius": "8px",
        "background-color": "secondary.50",
        "padding": "8px",
        "text-align": "center",
        "font-size": "12px",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.description) || '') + " ")]);
  }), 1)], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "padding": "8px"
    }
  }, _vm._l(_vm.programFacilities, function (facility, index) {
    var _vm$convertJsonFormat2, _vm$data18, _vm$data18$comparatio;

    return _c('c-box', {
      key: "facility-".concat(index)
    }, [_c('c-box', {
      attrs: {
        "background-color": "primary.50",
        "padding-inline": "8px",
        "padding-block": "6px",
        "text-align": "center",
        "font-size": "12px"
      },
      domProps: {
        "innerHTML": _vm._s(_vm.isObjectFacility(facility) ? (_vm$convertJsonFormat2 = _vm.convertJsonFormat(facility)) === null || _vm$convertJsonFormat2 === void 0 ? void 0 : _vm$convertJsonFormat2.mobile : facility)
      }
    }), _c('c-grid', {
      attrs: {
        "width": "100%",
        "template-columns": "repeat(".concat(_vm.data.comparation.length, ", 1fr)"),
        "padding-block": "6px"
      }
    }, _vm._l((_vm$data18 = _vm.data) === null || _vm$data18 === void 0 ? void 0 : (_vm$data18$comparatio = _vm$data18.comparation) === null || _vm$data18$comparatio === void 0 ? void 0 : _vm$data18$comparatio.length, function (j) {
      var _vm$data19, _vm$data19$comparatio, _vm$data20, _vm$data20$comparatio, _vm$data21, _vm$data21$comparatio, _vm$data22, _vm$data22$comparatio;

      return _c('c-flex', {
        key: "td-".concat(index, "-").concat(j),
        attrs: {
          "width": "100%",
          "flex-direction": "column",
          "align-items": "center",
          "padding-block": "4px",
          "font-size": "12px",
          "border-left": j !== 1 ? '1px solid #C4C4C4' : ''
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility, (_vm$data19 = _vm.data) === null || _vm$data19 === void 0 ? void 0 : (_vm$data19$comparatio = _vm$data19.comparation[j - 1]) === null || _vm$data19$comparatio === void 0 ? void 0 : _vm$data19$comparatio.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$data20 = _vm.data) === null || _vm$data20 === void 0 ? void 0 : (_vm$data20$comparatio = _vm$data20.comparation[j - 1]) === null || _vm$data20$comparatio === void 0 ? void 0 : _vm$data20$comparatio.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility, (_vm$data21 = _vm.data) === null || _vm$data21 === void 0 ? void 0 : (_vm$data21$comparatio = _vm$data21.comparation[j - 1]) === null || _vm$data21$comparatio === void 0 ? void 0 : _vm$data21$comparatio.productFacilitiesComparation)) ? 'var(--program-400)' : '#D32737'
        }
      }) : _c('c-box', {
        attrs: {
          "as": "span",
          "text-align": "center"
        }
      }, [_vm._v(" " + _vm._s(_vm.getFacilityValue(facility, (_vm$data22 = _vm.data) === null || _vm$data22 === void 0 ? void 0 : (_vm$data22$comparatio = _vm$data22.comparation[j - 1]) === null || _vm$data22$comparatio === void 0 ? void 0 : _vm$data22$comparatio.productFacilitiesComparation)) + " ")])], 1);
    }), 1)], 1);
  }), 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "padding-block": "8px"
    }
  }, [_c('c-grid', {
    attrs: {
      "template-columns": "repeat(".concat(_vm.data.comparation.length, ", 1fr)"),
      "width": "100%"
    }
  }, _vm._l((_vm$data23 = _vm.data) === null || _vm$data23 === void 0 ? void 0 : _vm$data23.comparation, function (item, q) {
    return _c('c-box', {
      key: "buy-".concat(item === null || item === void 0 ? void 0 : item.id),
      attrs: {
        "padding-inline": "8px",
        "border-left": q !== 0 ? '1px solid #C4C4C4' : ''
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "align-items": "center",
        "justify-content": "center",
        "gap": "12px"
      }
    }, [_c('c-text', {
      attrs: {
        "text-align": "center",
        "font-size": "20px",
        "font-weight": "700"
      }
    }, [_vm._v(" " + _vm._s(_vm.currency(item === null || item === void 0 ? void 0 : item.price, {
      separator: '.',
      precision: 0,
      symbol: 'Rp '
    }).format()) + " ")]), _c('c-flex', {
      attrs: {
        "width": "100%",
        "gap": ['8px', '16px']
      }
    }, [_vm.isAuthenticated ? _c('c-flex', {
      style: item !== null && item !== void 0 && item.isDisablePurchase ? 'pointer-events: none; opacity: 0.5;' : '',
      attrs: {
        "justify-content": "center",
        "align-items": "center",
        "height": ['34px', '50px'],
        "width": ['34px', '50px'],
        "min-height": ['34px', '50px'],
        "min-width": ['34px', '50px'],
        "border": "1px solid #008C81",
        "border-radius": "12px",
        "cursor": item !== null && item !== void 0 && item.isDisablePurchase || _vm.isLoading.addToCart ? 'not-allowed' : 'pointer',
        "_hover": !(item !== null && item !== void 0 && item.isDisablePurchase) ? {
          backgroundColor: '#e9fffb'
        } : {}
      },
      on: {
        "click": function click($event) {
          var _vm$data24, _vm$data24$product;

          !(item !== null && item !== void 0 && item.isDisablePurchase) && _vm.handleAddToCart({
            productServiceId: item === null || item === void 0 ? void 0 : item.id,
            comparation: item,
            photoUrl: (_vm$data24 = _vm.data) === null || _vm$data24 === void 0 ? void 0 : (_vm$data24$product = _vm$data24.product) === null || _vm$data24$product === void 0 ? void 0 : _vm$data24$product.photoUrl
          });
        }
      }
    }, [_vm.isLoading.addToCart ? _c('c-circular-progress', {
      attrs: {
        "is-indeterminate": "",
        "color": "primary",
        "size": "100%"
      }
    }) : _c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-cart.svg'),
        "width": "100%",
        "min-width": "100%",
        "fill": "#008C81"
      }
    })], 1) : _vm._e(), _c('BaseButton', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          color: 'primary.400',
          fontWeight: ['600', '600'],
          fontSize: ['12px', '18px']
        },
        expression: "{\n                      color: 'primary.400',\n                      fontWeight: ['600', '600'],\n                      fontSize: ['12px', '18px']\n                    }"
      }],
      attrs: {
        "size": "small",
        "variant": "contained",
        "w": "100%",
        "border-radius": "1000px",
        "color": "secondary",
        "is-loading": _vm.isLoading.buyNow,
        "disabled": item === null || item === void 0 ? void 0 : item.isDisablePurchase
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickBuyNow({
            productId: item === null || item === void 0 ? void 0 : item.productId,
            productServiceId: item === null || item === void 0 ? void 0 : item.id,
            duration: item === null || item === void 0 ? void 0 : item.duration
          });
        }
      }
    }, [_vm._v(" Beli Sekarang ")])], 1)], 1)], 1);
  }), 1)], 1), _c('c-text', {
    attrs: {
      "padding": ['8px', '16px'],
      "font-size": ['12px', '16px'],
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.additionalInfo) + " ")])], 1)], 1) : _vm._e(), _c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "padding-top": ['8px', '16px'],
      "font-size": ['12px', '16px'],
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.additionalInfo) + " ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1100px",
      "margin-inline": "auto",
      "margin-top": ['20px', '30px']
    }
  }, [_vm.isAuthenticated ? _c('Testimoni') : _vm._e(), !_vm.isAuthenticated ? _c('Promos') : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }